import Vue from 'vue';
import Router from 'vue-router';
import SignRouter from './modules/Sign';
import HomeRouter from './modules/Home';
import { auth, db } from '../firebase';
import { getDoc, doc } from 'firebase/firestore';


Vue.use(Router);

const routes = [
  ...SignRouter,
  ...HomeRouter,
  { path: '/', redirect: '/login' },
  { path: '*', redirect: '/' },
];

const router = new Router({
  mode: 'history',
  base: '/',
  routes: routes,
});

router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      if (to.matched.some(record => record.meta.requiresAdmin)) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();

          if (userData && userData.role === 'admin') {
            next(); // 관리자인 경우 계속 진행
          } else {
            next('/'); // 관리자가 아닌 경우 홈 페이지로 리다이렉트
          }
        } catch (error) {
          console.error('사용자 데이터를 가져오는 중 오류가 발생했습니다.', error);
          next('/'); // 오류가 발생한 경우 홈 페이지로 리다이렉트
        }
      } else {
        next(); // requiresAdmin이 아닌 모든 페이지는 계속 진행
      }
    } else {
      if (to.path === '/login' || to.path === '/signup') {
        next(); // 로그인하지 않은 경우 로그인 및 회원가입 페이지로는 계속 진행
      } else {
        next('/login'); // 로그인하지 않은 경우 다른 페이지는 로그인 페이지로 리다이렉트
      }
    }
  });
});

export default router;
