<template>
  <Section id="homeView">
    <!--    <Header />-->
    <section id="main_container">
      <div class="inner">
        <div class="contents_box">
          <article class="contents">
            <header class="top">
              <div class="user_container">
                <div class="profile_img">
                  <img src="../../assets/images/icon_profile_landing.png"/>
                </div>
                <div class="user_name">
                  <div class="nick_name m_text">주인장</div>
                </div>
              </div>
            </header>
            <div class="content_box">
              <div class="text_item">
                <p>가입해주셔서 감사합니다~!!
                  S는 자유로운 커뮤니티니 어떤 글이든 어떤사진이든 자유롭게 공유하고 소통하세요!!<br>
                  (아직 미흡해 업데이트 꾸준히 할것이니 기대해주세요!!)
                </p>
              </div>
            </div>
          </article>
          <article class="contents" v-for="p in posts" :key="p.id">
            <header class="top">
              <div class="user_container">
                <div class="profile_img">
                  <img src="../../assets/images/icon_profile_landing.png"/>
                </div>
                <div class="user_name">
                  <div class="nick_name m_text">{{ p.userName }}</div>
                </div>
              </div>
                <el-dropdown v-if="p.userId === user.uid" trigger="click" >
                <i class="el-icon-more"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="editPost(p.id)">수정</el-dropdown-item>
                  <el-dropdown-item @click.native="deletePost(p.id)">삭제</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </header>

            <div class="content_box">
              <!--              기존에 사진여러장 고려안하고 개발해서 일단 냅둠-->
              <div class="img_section" v-if="p.photoURL">
                <!--                <img v-if="!isVideo(p.photoURL)" :src="p.photoURL" />-->
                <!--                <video v-else :src="p.photoURL" controls width="100%" height="100%" loop></video>-->
                <img :src="p.photoURL"/>
              </div>
              <div class="img_section" v-if="p.photoURLs && p.photoURLs.length > 0">
                <swiper :options="swiperOption" class="swiper">
                  <swiper-slide v-for="(photoURL, index) in p.photoURLs" :key="index">
                    <!--                    <img v-if="!isVideo(p.photoURL)" :src="p.photoURL" />-->
                    <!--                    <video v-else :src="p.photoURL" controls loop></video>-->
                    <img :src="photoURL"/>
                  </swiper-slide>
                  <div class="swiper-pagination"></div>
                  <!--                  <div class="swiper-button-prev"></div>-->
                  <!--                  <div class="swiper-button-next"></div>-->
                </swiper>
              </div>
              <div class="text_item">
                <p>{{ p.content }}</p>
              </div>
            </div>
            <div class="bottom_icons">
              <div class="left_icons">
                <div class="heart_btn" @click="toggleLike(p)">
                  <img v-if="!p.heart" src="../../assets/images/heart.png" style="width: 24px; cursor: pointer"/>
                  <img v-else src="../../assets/images/hearts2.png" style="width: 24px; cursor: pointer"/>
                </div>
                <div class="comment_btn">
                  <i class="el-icon-chat-round"></i>
                </div>
              </div>
            </div>

            <div class="likes m_text">
              좋아요
              <span id="like-count-39">{{ p.likes }}</span> 개
            </div>
            <div class="comment_container">
              <div class="comment" v-for="comment in p.comments.slice().reverse()" :key="comment.id">
                <div class="comment-detail">
                  <div class="detail_1">
                    <img src="../../assets/images/icon_profile_landing.png" style="height: 20px"/>
                    <div class="detail_1_img">
                      <div class="nick_name m_text">{{ comment.userName }}</div>
                      <div>{{ comment.text }}</div>
                    </div>
                  </div>
                  <div class="comment_time">{{ getCommentElapsedTime(comment.createdAt) }}</div>
                </div>
              </div>
            </div>

            <div class="timer">{{ getElapsedTime(p.createdAt) }}</div>

            <div class="comment_field" id="add_comment">
              <el-input type="text" v-model="newCommentText[p.id]" placeholder="댓글달기..."/>
              <div class="upload_btn m_text" data-name="comment" @click="addComment(p.id)">게시</div>
            </div>
          </article>
          <p v-if="posts.length == 0">게시물이 없습니다.</p>
        </div>
        <input type="hidden" id="page" value="1"/>
      </div>
    </section>
  </Section>
</template>

<script>
  import {Swiper, SwiperSlide} from "vue-awesome-swiper";
  import "swiper/css/swiper.css";
  //  import Header from "@/layout/Header";
  import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where
  } from "firebase/firestore";
  import {getAuth, onAuthStateChanged} from "firebase/auth";

  export default {
    name: "Home",
    components: {
//      Header,
      Swiper, SwiperSlide
    },
    data() {
      return {
        posts: [],
        user: null,
        newCommentText: {},
        swiperOption: {
          slidesPerView: 1,
//          spaceBetween: 10,
          centeredSlides: true,
          autoHeight: true,
          loop: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
      };
    },
    methods: {
      //비디오 있는지 없는지 확인하는 함순데 뭔가 잘작동안함 일단 주석처리!!
//      isVideo(url) {
//        return /\.(mp4|mov|avi|wmv|flv|mkv)$/.test(url);
//      },
      async toggleLike(post) {
        const postId = post.id;
        const userId = this.user.uid;
        const db = getFirestore();
        const likeDocRef = doc(db, "likes", `${userId}_${postId}`);
        const likeDoc = await getDoc(likeDocRef);

        if (likeDoc.exists()) {
          await deleteDoc(likeDocRef);
          post.likes -= 1;
          post.heart = false;
        } else {
          await setDoc(likeDocRef, {
            userId: userId,
            postId: postId,
          });
          post.likes += 1;
          post.heart = true;
        }

        const postRef = doc(db, "posts", postId);
        await updateDoc(postRef, {
          likes: post.likes,
        });
      },
      async fetchPosts() {
        const db = getFirestore();
        const postsCollection = collection(db, "posts");
        const postsQuery = query(postsCollection, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(postsQuery);

        this.posts = await Promise.all(
            querySnapshot.docs.map(async (postDoc) => {
              const data = postDoc.data();
              const post = {id: postDoc.id, likes: data.likes, ...data, comments: []};
              const likeDocRef = doc(db, "likes", `${this.user.uid}_${post.id}`);
              const likeDoc = await getDoc(likeDocRef);
              post.heart = likeDoc.exists();
              // 포스트에 대한 댓글 가져오기
              const commentsCollection = collection(db, "comments");
              const commentsQuery = query(commentsCollection, where("postId", "==", post.id));
              const commentsSnapshot = await getDocs(commentsQuery);

              commentsSnapshot.forEach((commentDoc) => {
                const commentData = commentDoc.data();
                const comment = {id: commentDoc.id, ...commentData};
                // 댓글의 생성 시간이 존재할 경우에만 toDate() 호출
                if (commentData.createdAt) {
                  comment.createdAt = commentData.createdAt.toDate();
                }
                post.comments.push(comment);
              });

              return post;
            })
        );
      },
      async addComment(postId) {
        const commentText = this.newCommentText[postId];
        if (!commentText) return;

        const db = getFirestore();
        const commentsCollection = collection(db, "comments");
        const timestamp = new Date(); // 현재 시간을 가져옴
        const userDocRef = doc(db, "users", this.user.uid); // 사용자 문서의 참조 가져오기
        const userDocSnap = await getDoc(userDocRef); // 사용자 문서 가져오기
        const userName = userDocSnap.exists() ? userDocSnap.data().name : "Anonymous"; // 사용자 이름 가져오기
        await addDoc(commentsCollection, {
          postId: postId,
          userId: this.user.uid,
          userName: userName,
          text: commentText,
          createdAt: timestamp, // 댓글 생성 시간을 설정
        });

        // 댓글 추가 후 입력 필드 초기화
        this.newCommentText[postId] = "";

        // 포스트 다시 불러오기
        await this.fetchPosts();
      },
      async deletePost(postId) {
        const db = getFirestore();
        try {
          await deleteDoc(doc(db, "posts", postId));
          this.posts = this.posts.filter(post => post.id !== postId);
        } catch (error) {
          console.error("Error deleting post:", error);
        }
      },
//      editPost(postId) {
//        // 여기서 수정 기능을 구현합니다.
//        // 예를 들어, 수정 모달을 띄운 후 수정 내용을 업데이트 하는 로직을 추가할 수 있습니다.
//      },
      getCommentElapsedTime(timestamp) {
        const now = new Date();
        const elapsed = now - timestamp;
        const seconds = Math.floor(elapsed / 1000);
        if (seconds < 60) {
          return `${seconds}초 전`;
        } else if (seconds < 3600) {
          return `${Math.floor(seconds / 60)}분 전`;
        } else if (seconds < 86400) {
          return `${Math.floor(seconds / 3600)}시간 전`;
        } else {
          return `${Math.floor(seconds / 86400)}일 전`;
        }
      },
      getElapsedTime(timestamp) {
        const createdAt = timestamp.toDate();
        const year = createdAt.getFullYear();
        const month = ("0" + (createdAt.getMonth() + 1)).slice(-2);
        const date = ("0" + createdAt.getDate()).slice(-2);
        const hours = ("0" + createdAt.getHours()).slice(-2);
        const minutes = ("0" + createdAt.getMinutes()).slice(-2);
        return `${year}-${month}-${date} ${hours}:${minutes}`;
      },
    },
    async created() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.user = user;
          await this.fetchPosts();
        } else {
          this.user = null;
          this.posts = [];
        }
      });
    },
  };
</script>
