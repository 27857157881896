<template>
  <div id="app">
    <Header v-if="isLoggedIn" @drawer="drawer= true"/>
    <router-view/>
    <el-drawer
        size="70%"
        :visible.sync="drawer"
        :before-close="handleClose">
      <ul>
        <li v-for="m in MENU" :key="m.id"
            :class="{'is-active': $route.path === m.url}"
            @click="m.func ? exeFunc(m.func) : menuClick(m.url)">
          <i style="margin-right: 4px" :class="m.icon"></i>
          {{ m.text }}
        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Header from "./layout/Header";
//  import {signOut} from "firebase/auth";
//  import {auth} from "@/firebase";

  const MENU = [{
    id: 1, text: "게시물 작성", url: "/post",icon:"el-icon-circle-plus-outline"
  },
    {
    id: 2, text: "알림", url: "/solution",icon: "el-icon-message-solid", func:'isAlready'
  },
    {
      id: 3, text: "프로필", url: "/profile", icon: "el-icon-s-custom"
    },
    {
      id: 4, text: "로그아웃", url: "/oversea/solution", icon: "el-icon-switch-button", func:'logout'
    },
  ]

export default {
  name: 'App',
  components: {
    Header,
  },
  data() {
    return {
      drawer: false, MENU,
    }
  },
  computed: {
    ...mapGetters(['getIsLoggedIn']),
    isLoggedIn() {
      return this.getIsLoggedIn;
    },
//    ...mapGetters({
//      isLoggedIn: 'getIsLoggedIn'
//    }),
  },
  methods:{
    ...mapActions(['login', 'logout', 'checkLoginState']),
    isAlready() {
      this.$alert('준비중!!', '알림');
    },
    async logout() {
      try {
        const confirmed = await this.$confirm('로그아웃 하시겠습니까?', '알림', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning'
        });
        if (confirmed) {
          await this.$store.dispatch('logout');
//          this.$router.push('/login');
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('Error during logout:', error.message);
        }
      }
    },
    exeFunc(func){
      this[func]();
      this.drawer = false
    },
    menuClick(url) {
      this.$router.push(url)
      this.drawer = false
    },
    handleClose() {
      this.drawer = false
    },
  },
  created() {
    this.checkLoginState();
  }
}
</script>
