<template>
  <Section id="header">
    <div class="header_box">
      <div class="logo" @click="$router.push('/')" style="cursor: pointer">
        <img src="../assets/images/logo.png" style="height: 30px">
      </div>

      <div class="search_box">
        <el-input v-model="search" placeholder="검색"/>
<!--            @input="debounce(500)"/>-->
      </div>

      <div class="right_icons">
        <i @click="$router.push('/post')" class="el-icon-circle-plus-outline"></i>
        <i @click="isAlready" class="el-icon-message-solid"></i>
        <i @click="$router.push('/profile')" class="el-icon-s-custom"></i>
        <i @click="logout" class="el-icon-switch-button"></i>
      </div>
      <div class="mobile_icon">
        <img src="../assets/images/mobile_menu.png" height="30px"
            @click="$emit('drawer', true)" class="mh-btn"/>
      </div>
    </div>

  </Section>
</template>

<script>
//  import { signOut } from 'firebase/auth';
//  import { auth } from '../firebase';
  import {mapActions} from "vuex";
//  import { mapActions } from 'vuex';



  export default {
    name: "Header",
    data(){
      return{
        search:'',
      }
    },
    methods:{
      ...mapActions([ 'logout']),
      isAlready(){
        this.$alert('준비중!!', '알림');
      },
      async logout() {
        try {
          const confirmed = await this.$confirm('로그아웃 하시겠습니까?', '알림', {
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            type: 'warning'
          });
          if (confirmed) {
            await this.$store.dispatch('logout');
            this.$router.push('/login');
          }
        } catch (error) {
          if (error !== 'cancel') {
            console.error('Error during logout:', error.message);
          }
        }
      }
//      debounce(delay) {
//        const vm = this
//        clearTimeout(vm.searchTimer);
//        vm.searchTimer = setTimeout(() => {
//          vm.isLoading = true
//          vm.searchList = []
//          vm.filterPartner(vm.partnerList)
//          vm.isLoading = false
//        }, delay);
//      },
    },
  }
</script>
