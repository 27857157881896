<template>
  <div id="Post">
    <Header/>
    <div id="main_container">
      <div class="post_form_container">
        <el-form class="post_form">
          <div class="title">NEW POST</div>
          <div class="preview">
            <div class="upload">
              <div v-if="photoPreviews.length === 0" class="post_btn">
                <div class="plus_icon">
                  <span></span>
                  <span></span>
                </div>
                <p>포스트 이미지 추가</p>
              </div>
<!--              <canvas v-show="photoPreview" id="imageCanvas"></canvas>-->
              <swiper v-else class="swiper" :options="swiperOption">
                <swiper-slide v-for="(photo, index) in photoPreviews" :key="index">
                  <img :src="photo" class="preview-img"/>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
<!--                <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--                <div class="swiper-button-next" slot="button-next"></div>-->
              </swiper>
            </div>
          </div>
          <p>
            <input type="file" name="photo" id="id_photo" @change="onFileChange" multiple>
          </p>
          <p>
            <textarea name="content" id="text_field" cols="50" rows="5" v-model="content"
                      placeholder="140자 까지 등록 가능합니다.#태그명 을 통해서 검색 태그를 등록할 수 있습니다.">
            </textarea>
          </p>
          <el-button class="submit_btn" @click="submit" type="primary">공유</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../../layout/Header';
  import {addDoc, collection, doc, getDoc, getFirestore, serverTimestamp} from 'firebase/firestore';
  import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
  import {auth} from '../../firebase';
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import "swiper/css/swiper.css";

  export default {
    name: "Post",
    components: {Header,Swiper, SwiperSlide},
    data() {
      return {
        photo: [],
        photoPreviews: [],
        content: '',
        name: '',
        date:'',
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
      };
    },
    methods: {
      onFileChange(event) {
        const files = event.target.files;
        this.photo = Array.from(files);

        this.photoPreviews = [];

        Array.from(files).forEach(file => {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.photoPreviews.push(e.target.result);
          };
          reader.readAsDataURL(file);
        });
      },
      async submit() {
        const vm = this;
        if (!vm.photo && !vm.content.trim()) {
          vm.$alert('사진이나 텍스트 중 하나를 입력해야 합니다.', '알림');
          return;
        }

        try {
          const user = auth.currentUser; // 현재 로그인한 사용자 가져오기
          if (!user) {
            vm.$alert('사용자가 인증되지 않았습니다.', '오류');
            return;
          }
          const userDoc = await getDoc(doc(getFirestore(), 'users', user.uid));
          const userName = userDoc.data().name;
          let photoURLs = [];

          for (let i = 0; i < vm.photo.length; i++) {
            const storageRef = ref(getStorage(), `posts/${vm.photo[i].name}`);
            await uploadBytes(storageRef, vm.photo[i]);
            const photoURL = await getDownloadURL(storageRef);
            photoURLs.push(photoURL);
          }

          await addDoc(collection(getFirestore(), 'posts'), {
            content: vm.content,
            photoURLs: photoURLs,
            createdAt: serverTimestamp(),
            userName: userName,
            userId: user.uid,
            likes: 0, // 좋아요 수를 나타내는 필드를 추가하고 초기값으로 0을 할당
          });
          vm.$alert('작성 완료!!', '알림');
          vm.$router.push('/');
        } catch (error) {
          console.error('Error submitting post:', error);
          vm.$alert('포스트 업로드 중 오류가 발생했습니다.', '알림');
        }
      },
    },
  };
</script>
