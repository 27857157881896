import Home from '../../views/home/Home';
//import { auth } from '../../firebase.js';
import Post from '../../views/home/Post'

const routes = [
  {
    path: '/',
    name: 'HomeIndex',
    component: Home,
    beforeEnter: (to, from, next) => {
      const userToken = localStorage.getItem('userToken');
      if (userToken) {
        // 토큰이 있으면 사용자가 로그인한 것으로 간주합니다.
        next();
      } else {
        // 토큰이 없으면 사용자가 로그인하지 않은 것으로 간주하고 로그인 페이지로 리다이렉트합니다.
        next('/login');
      }
    },
  },
  {
    path: '/post',
    name: 'Post',
    component: Post,
  },
];

export default routes;
