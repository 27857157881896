import { signOut } from 'firebase/auth';
import { auth } from '@/firebase';

export default {
  login({ commit }, userToken) {
    localStorage.setItem('userToken', userToken);
    commit('setLoginState', { isLoggedIn: true, userToken });
  },
  async logout({ commit }) {
    await signOut(auth);
    localStorage.removeItem('userToken');
    commit('setLoginState', { isLoggedIn: false, userToken: null });
  },
  checkLoginState({ commit }) {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      commit('setLoginState', { isLoggedIn: true, userToken });
    } else {
      commit('setLoginState', { isLoggedIn: false, userToken: null });
    }
  },
};
