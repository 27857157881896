import Vue from 'vue'
import App from './App.vue'
import './assets/styles/main.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueRouter from 'vue-router'
import locale from 'element-ui/lib/locale/lang/ko';
import router from './router'
import { Drawer } from 'element-ui';
import store from "./common/store";
//import { setupAutoLogout } from './firebase';


Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(ElementUI, { locale });
Vue.use(Drawer);

// 사용자 인증 상태 확인
//auth.onAuthStateChanged((user) => {
//  if (user) {
//    console.log('User', user);
//  } else {
//    console.log('No user');
//  }
//});

//setupAutoLogout();


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
