import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword,signOut,onAuthStateChanged,
//  createUserWithEmailAndPassword, fetchSignInMethodsForEmail
} from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, doc, getDoc, updateDoc, setDoc, } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC5GhT6Tmb4V4ERhT03u3VFjbqKj-Q1l3k",
  authDomain: "easylogin-a05a8.firebaseapp.com",
  projectId: "easylogin-a05a8",
  storageBucket: "easylogin-a05a8.appspot.com",
  messagingSenderId: "521986150924",
  appId: "1:521986150924:web:6c72ecbb553ea9c84c9429",
  measurementId: "G-J14BE97T0J"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export { auth, db, collection, getDocs, query, where,
  doc, getDoc, updateDoc, setDoc,
  signInWithEmailAndPassword,signOut,getCurrentUser };

// 이메일이 이미 존재하는지 확인하는 함수
export async function CheckUsername(username) {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('username', '==', username));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
}

// 닉네임이 이미 존재하는지 확인하는 함수
export async function checkNicknameExists(nickname) {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('name', '==', nickname));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
}

// 관리자 계정 설정
//
//async function createAdminAccount(email, password) {
//  const signInMethods = await fetchSignInMethodsForEmail(auth, email);
//  if (signInMethods.length === 0) {
//    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//    const user = userCredential.user;
//    const userDocRef = doc(db, 'users', user.uid);
//
//    await setDoc(userDocRef, {
//      name:'주인장',
//      email: email,
//      role: 'admin', // 관리자 역할 설정
//      approved: true, // 관리자 계정은 자동으로 승인
//    });
//
//    console.log('Admin account created:', user.email);
//  } else {
//    console.log('Admin account already exists for email:', email);
//  }
//}
//
//// 예시로 관리자 계정 생성 (실제 사용 시 주의)
//createAdminAccount('song0717@nate.com', 'rjseka485');
