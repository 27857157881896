const Login = () => import(/* webpackChunkName: "sign" */ '../../views/sign/Login.vue');
const SignUp = () => import(/* webpackChunkName: "sign" */ '../../views/sign/SignUp.vue');
const AdminApproval = () => import(/* webpackChunkName: "home" */ '../../views/sign/admin');

const SignRouter = [
  {
    path: '/login', name: 'Login', component: Login,
//    meta: {requiresAuth: false, layout: 'Sign'}
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignUp,
  },
  {
    path: '/admin/approval',
    name: 'AdminApproval',
    component: AdminApproval,
    meta: { requiresAdmin: true },
  },
];

export default SignRouter;
